import axios from 'axios'
import * as API from '../API-URL/index.js'
export default {
    vaccineSchedule(data, res) {

        axios.post(API.schedule_URL, data,
            {
                headers: {
                    'Authorization': process.env.REACT_APP_ACCESS_TOKEN,
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if (response) {
                    res(response.data)
                }
            })
            .catch((error) => {
                res(error.response)

            })
    },


}