import React from "react";
import logo from "../assets/popper.png";
import { Helmet } from "react-helmet";
export default function Header() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{process.env.REACT_APP_LAB_NAME}</title>

        <meta
          name="description"
          content={process.env.REACT_APP_LAB_NAME}
        />
      </Helmet>
      <div className="headerHolder">

        <div className="lefImage">
          <div>
            <h1> United States Virgin Islands Covid-19 Vaccine Initiative</h1>
          </div>
        </div>

        <div className="rightImage">{/* <img src={}/> */}</div>
      </div>
    </>
  );
}
