import { Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Calendly from './Components/Calendly.js'
import Form from './Components/Form.js'
import Success from './Components/Success.js'

function App() {
  return (
    <>
    <Router>
          <Route path="/" exact component={Form} />
          <Route path="/calendlyview" exact component={Calendly} />
          <Route path="/success" exact component={Success} />
        </Router>
    </>
  );
}

export default App;
