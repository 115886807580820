import React, { useEffect, useRef } from "react";
import axios from "axios";
import "date-fns";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import {
  Typography,
  Grid,
  Select,
  MenuItem,
  FormGroup,
  InputLabel,
  FormHelperText,
  Checkbox,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "./Form.css";
import Modal from "@material-ui/core/Modal";
import close from "../assets/close.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header.js";
import Footer from "./Footer.js";
import RegisterBanner from "./RegisterBanner.js";
import VaccineScheduling from "../assets/VaccineScheduling.pdf";
import { SignalCellularNull } from "@material-ui/icons";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

//Modal Box Material UI
function getModalStyle() {
  const top = 50;
  const left = 50;
  // const height = "100vh";

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
// Material UI Global Styles
const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  input: {
    color: "#00AFB9",
  },
  paper: {
    position: "absolute",

    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "20px",
  },
}));

//Material UI - Accordion styles
const Accordion = withStyles({
  root: {
    margin: "2em",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "2em",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#fff",
    color: "#00AFB9",
    boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function Form() {
  const classes = useStyles();

  //====Scrolling Ref====//
  const myRef = useRef(null);
  const myRef1 = useRef(null);
  const myRef2 = useRef(null);
  const myRef3 = useRef(null);

  //====Form Initialization====//
  const initialFormData = Object.freeze({
    //first accordion
    firstName: new URLSearchParams(window.location.search).get(
      "invitee_first_name"
    )
      ? new URLSearchParams(window.location.search).get("invitee_first_name")
      : "",
    middleName: "",
    lastName: new URLSearchParams(window.location.search).get(
      "invitee_last_name"
    )
      ? new URLSearchParams(window.location.search).get("invitee_last_name")
      : "",
    email: new URLSearchParams(window.location.search).get("invitee_email")
      ? new URLSearchParams(window.location.search).get("invitee_email")
      : "",
  });

  //====Form States====//
  const [formstate, setformState] = React.useState(initialFormData);
  const [countyTemp, setCountyTemp] = React.useState([]);
  const [stateSelect, setstateSelect] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [raced, setRaced] = React.useState([]);
  const [calendlyStartTime, setcalendlyStartTime] = React.useState("");
  const [getqueryString, CheckqueryString] = React.useState("");
  const [country, setCountry] = React.useState("US");
  const [city, setCity] = React.useState("");
  const [countryList, setCountryList] = React.useState([]);
  const [zipCode, setzipCode] = React.useState("");
  const [addressOne, setaddressOne] = React.useState("");
  const [addressTwo, setaddressTwo] = React.useState("");
  const [describeDisablity, setdescribeDisablity] = React.useState("");
  const [comments, setcomments] = React.useState("");
  const [occupation, setoccupation] = React.useState("");

  const [confirmationCode, setConfirmationCode] = React.useState("");
  const [getAgelimit, setAgelimit] = React.useState(false);

  //====Accordion Expand State====//
  const [expanded, setExpanded] = React.useState("panel1");

  //====Panel Active state====//
  const [active1, setActive1] = React.useState(false);
  const [active2, setActive2] = React.useState(false);
  const [active3, setActive3] = React.useState(false);
  const [active4, setActive4] = React.useState(false);

  //====symptom date state====//
  const [selectedDateSymptom, setSelectedDateSymptom] = React.useState(null);
  const [selectedVaccine, setSelectedVaccine] = React.useState(null);
  //===== Insurance state=====//
  const [primaryInsuranceCompanyName, setprimaryInsuranceCompanyName] =
    React.useState("");
  const [primaryCarrierId, setprimaryCarrierId] = React.useState("");
  const [primaryGroupId, setprimaryGroupId] = React.useState("");
  const [secondaryInsuranceCompanyName, setsecondaryInsuranceCompanyName] =
    React.useState("");
  const [secondaryCarrierId, setsecondaryCarrierId] = React.useState("");
  const [secondaryGroupId, setsecondaryGroupId] = React.useState("");

  //====State for Child age check====//
  const [ChildCheck, setChildCheck] = React.useState(process.env.REACT_APP_LAB_ID === "113" ||
  process.env.REACT_APP_LAB_ID === "117"? "Yes":"");
  const [symptomatic, setSymptomatic] = React.useState("");
  const [EduCheck, setEduCheck] = React.useState("");
  const [Covid19vaccine, setCovid19vaccine] = React.useState("");
  const [allergicToAnything, setallergicToAnything] = React.useState("");
  const [seriousReaction, setseriousReaction] = React.useState("");
  const [weakenedImmuneSystem, setweakenedImmuneSystem] = React.useState("");
  const [conditionsCheck, setconditionsCheck] = React.useState("");
  const [treatmentCheck, settreatmentCheck] = React.useState("");
  const [diagnosedCovid, setdiagnosedCovid] = React.useState("");
  const [consent, setconsent] = React.useState("");
  const [parentGuardianSignature, setparentGuardianSignature] =
    React.useState("");
  const [consentDate, setconsentDate] = React.useState(null);

  //====symptom list state====//
  const initialFormData1 = Object({
    Shortness_of_breath: false,
    Cough: false,
    Chills: false,
    Fever: false,
    Difficulty_breathing: false,
    Headache: false,
    Fatigue: false,
    Diarrhea: false,
    Vomiting: false,
    Sore_Throat: false,
    Nausea: false,
    Loss_of_taste: false,
    Body_and_or_muscle_aches: false,
    Congestion_and_or_runny_nose: false,
  });
  const [stateChecked, setStateCheck] = React.useState(initialFormData1);

  //====State and County Value====//
  const [state, setState] = React.useState("VI,Virgin Islands");
  const [county, setCounty] = React.useState(
    process.env.REACT_APP_LAB_ID === "14" ||
      process.env.REACT_APP_LAB_ID === "117"
      ? "St. Croix"
      : process.env.REACT_APP_LAB_ID === "15" ||
        process.env.REACT_APP_LAB_ID === "113"
      ? "St. Thomas"
      : "St. John"
  );

  //====Mobile number State====//
  const [newnumber, setNumber] = React.useState("");
  const [dialCode, setdialCode] = React.useState("");

  const [ethnicity, setEthnicity] = React.useState("");
  const [disability, setdisability] = React.useState("");
  const [VaccineType, setVaccineType] = React.useState("");
  const [member, setMember] = React.useState("");
  const [vaccinedose, Setvaccinedose] = React.useState("");
  const [selectcvcmonth, setselectcvcmonth] = React.useState("");
  const [Checkvaccinedose, setCheckvaccinedose] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [stateCheckedNA, setStateCheckNA] = React.useState(false);
  const [sendMessageToMobileCheck, setsendMessageToMobileCheck] =
    React.useState(true);
  const [racedSelect, setRacedSelect] = React.useState("");
  const [newstateChecked, newsetStateCheck] = React.useState(false);
  const [gender, setGender] = React.useState("");
  const [pregnancy, setPregnancy] = React.useState("U");

  //====Error States====//
  const [errorChildCheck, updateChildErrorCheck] = React.useState();
  const [errorFirstData, updateFirstErrorData] = React.useState();
  const [errorLastData, updateLastErrorData] = React.useState();
  const [errorMobileData, updateMobileErrorData] = React.useState();
  const [errorEmailData, updateEmailErrorData] = React.useState();
  const [errorAddress, updateAddress] = React.useState();
  const [errorCountry, updateCountry] = React.useState();
  const [errorCounty, updateCounty] = React.useState();
  const [errorEducationCheck, updateEducationCheck] = React.useState();
  const [errorCity, updateCity] = React.useState();
  const [errorZip, updateZip] = React.useState();
  const [errorOccupation, updateOccupation] = React.useState();
  const [errorRace, updateRace] = React.useState();
  const [errorGender, updateGender] = React.useState();
  const [errorState, updateState] = React.useState();

  const [errorDateofbirth, updateDateofBirth] = React.useState();
  const [errorMember, seterrorMember] = React.useState();
  const [errorEthnicity, updateErrorEthnicity] = React.useState();
  const [errorVaccine, updateErrorVaccine] = React.useState();
  const [errorCVCmonth, updateErrorCVCmonth] = React.useState();

  const [errorVaccinedate, updateErrorVaccinedate] = React.useState();
  const [errorVaccinetype, updateErrorVaccinetype] = React.useState();
  const [errordisability, updateErrordisability] = React.useState();
  const [errordescribedisability, updateErrordescribeDisablity] =
    React.useState();

  const [symptomsError, setsymptomsError] = React.useState("");
  const [symptomsListError, setsymptomsListError] = React.useState("");
  const [symptomsDateError, setsymptomsDateError] = React.useState("");
  const [ReceivedVaccineError, setReceivedVaccineError] = React.useState("");
  const [allergicToAnythingError, setallergicToAnythingError] =
    React.useState("");
  const [seriousReactionError, setseriousReactionError] = React.useState("");
  const [weakenedImmuneSystemError, setweakenedImmuneSystemError] =
    React.useState("");
  const [conditionsCheckError, setconditionsCheckError] = React.useState("");
  const [treatmentCheckError, settreatmentCheckError] = React.useState("");
  const [diagnosedCovidError, setdiagnosedCovidError] = React.useState("");
  const [consentError, setconsentError] = React.useState("");
  const [parentGuardianSignatureError, setparentGuardianSignatureError] =
    React.useState("");
  const [consentDateError, setconsentDateError] = React.useState(null);

  //======Get CountryList======//
  const GetCountryStateList = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/bvi/country/list", {})
      .then((response) => {
        if (response) {
          setCountryList(response.data.info);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast(error.response.data);
        }
      });

    let data = {
      countryId: "233",
    };

    axios
      .post(
        process.env.REACT_APP_API_URL +
          "/bvi/state/list" +
          "?countryId=" +
          data.countryId
      )
      .then((response) => {
        if (response) {
          setCity("");
          let allEntries = Object.entries(response.data.info.states);
          let sortedEntries = allEntries.sort((a, b) =>
            a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
          );
          setstateSelect(sortedEntries);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast(error.response.data);
        }
      });

    let data1 = {
      stateId: "4966",
    };

    axios
      .post(
        process.env.REACT_APP_API_URL +
          "/bvi/city/list" +
          "?stateId=" +
          data1.stateId
      )
      .then((response) => {
        if (response) {
          let allEntries = Object.entries(response.data.info.cities);
          let sortedEntries = allEntries.sort((a, b) =>
            a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
          );
          setCountyTemp(sortedEntries);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.errorMessage);
        }
      });
  };

  //======Get Race list value======//
  const getRaces = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/web/dropdown/list", {
        headers: {
          Authorization: process.env.REACT_APP_ACCESS_TOKEN,
        },
      })
      .then((res) => {
        setRaced(res.data.info.races);
      });
  };

  //============On Change Functionalities=============//

  const onChangeChildCheck = (e) => {
    setChildCheck(e.target.value);
    setCheckvaccinedose("");
    Setvaccinedose("");
    setVaccineType("");
    setSelectedVaccine(null);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const Checkhandlevaccinedose = (event) => {
    setSelectedVaccine(null);
    if (event.target.value === "Yes") {
      if(process.env.REACT_APP_LAB_ID === "113" || process.env.REACT_APP_LAB_ID === "117"){
        setVaccineType("");
        setCheckvaccinedose(event.target.value);        
      }
      else{
        setOpen(true);
        setVaccineType("");
        setCheckvaccinedose(event.target.value);
      }
    } else if (event.target.value === "Booster") {
      setCheckvaccinedose(event.target.value);
      Setvaccinedose(event.target.value);
      setVaccineType("");
    } else {
      setCheckvaccinedose(event.target.value);
      Setvaccinedose(event.target.value);
      setVaccineType("");
    }
  };

  const handleDateChangeVaccine = (date) => {
    setSelectedVaccine(date);
  };

  const handleChangeVaccineType = (event) => {
    setVaccineType(event.target.value);
  };

  const handleChangeForm = (event) => {
    const name = event.target.name;
    let value = event.target.value.replaceAll('"', "'");
    setformState({
      ...formstate,
      [name]: value.replaceAll("''", "'"),
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const mobileNumberChange = (number, e) => {
    setdialCode(e.dialCode);
    setNumber(number);
  };

  const handleChangeMobileCheck = (event) => {
    setsendMessageToMobileCheck(event.target.checked);
  };

  const handleChangeNA = (event) => {
    setStateCheckNA(event.target.checked);
  };

  const handlevaccinedose = (event) => {
    setSelectedVaccine(null);
    if (event.target.value == "No") {
      setSelectedVaccine(null);
      setVaccineType("");
      setCheckvaccinedose("");
    } else if (event.target.value === "Booster") {
      Setvaccinedose(event.target.value);
      setCheckvaccinedose("");
      setVaccineType("");
    } else {
      setVaccineType("");
      setCheckvaccinedose("");
    }

    Setvaccinedose(event.target.value);
  };

  const handleChangedisability = (event) => {
    setdisability(event.target.value);
  };

  const handleChangeRace = (event) => {
    setRacedSelect(event.target.value);
  };

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  const handleChangeEthnicity = (event) => {
    setEthnicity(event.target.value);
  };

  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
    setCountyTemp([]);
    countryList.map((item) => {
      if (item.countryCode === event.target.value) {
        let data = {
          countryId: item.id,
        };
        axios
          .post(
            process.env.REACT_APP_API_URL +
              "/bvi/state/list" +
              "?countryId=" +
              data.countryId
          )
          .then((response) => {
            if (response) {
              setState("");
              setCity("");
              setCounty("");
              let allEntries = Object.entries(response.data.info.states);
              let sortedEntries = allEntries.sort((a, b) =>
                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
              );
              setstateSelect(sortedEntries);
            }
          })
          .catch((error) => {
            if (error.response) {
              toast(error.response.data);
            }
          });
      }
    });
  };

  const handleChangeState = (event) => {
    let check1 = event.target.value.split(",");
    setState(event.target.value);
    setCity("");
    setCounty("");

    stateSelect.map((item) => {
      if (item[0] == check1[1]) {
        let data = {
          stateId: item[1].id,
        };
        axios
          .post(
            process.env.REACT_APP_API_URL +
              "/bvi/city/list" +
              "?stateId=" +
              data.stateId
          )
          .then((response) => {
            if (response) {
              let allEntries = Object.entries(response.data.info.cities);
              let sortedEntries = allEntries.sort((a, b) =>
                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
              );
              setCountyTemp(sortedEntries);
            }
          })
          .catch((error) => {
            if (error.response) {
              toast(error.response.data);
            }
          });
      }
    });
  };

  const countyChange = (event) => {
    setCounty(event.target.value);
  };

  const handleSymptomatic = (event) => {
    setSymptomatic(event.target.value);
    if (event.target.value === "N") {
      setSelectedDateSymptom(null);
      setStateCheck(initialFormData1);
    }
  };

  const handleChangeCheck = (event) => {
    setStateCheck({
      ...stateChecked,
      [event.target.name]: event.target.checked,
    });
  };

  const handleDateChangeSymptom = (date) => {
    setSelectedDateSymptom(date);
  };

  const handleChangeCheckInsurance = (event) => {
    newsetStateCheck(event.target.checked);
  };

  const handleChangeFormPregnancy = (event) => {
    setPregnancy(event.target.value);
  };

  //=======End of On Change Functionalities=========//

  //=======Form Next Functionalities=========//
  const moveNext = () => {
    updateChildErrorCheck("");
    updateFirstErrorData("");
    updateLastErrorData("");
    updateMobileErrorData("");
    updateEmailErrorData("");
    updateEmailErrorData("");
    updateDateofBirth("");
    updateDateofBirth("");
    updateErrordescribeDisablity("");
    updateErrorVaccine("");
    updateErrorVaccinedate("");
    updateErrorVaccinetype("");
    updateErrorVaccinedate("");
    updateErrorVaccinetype("");

    //===Validation===//
    if (
      (process.env.REACT_APP_LAB_ID === "113" ||
        process.env.REACT_APP_LAB_ID === "117") &&
      ChildCheck == ""
    ) {
      updateChildErrorCheck("Please select the appointment for Child");
      myRef.current.scrollIntoView();
    } else if (
      (Checkvaccinedose === undefined || Checkvaccinedose === "") &&
      getqueryString === false
    ) {
      updateErrorVaccine("Please select vaccine dose");
      myRef.current.scrollIntoView();
    } else if (
      (Checkvaccinedose === "Booster" || Checkvaccinedose === "Third") &&
      selectedVaccine == null
    ) {
      updateErrorVaccinedate("Please select vaccine date");
      myRef.current.scrollIntoView();
    } else if (
      (Checkvaccinedose === "Booster" || Checkvaccinedose === "Third") &&
      VaccineType === ""
    ) {
      updateErrorVaccinetype("Please select vaccine type");
      myRef.current.scrollIntoView();
    } else if (
      formstate.firstName.trim() === "" &&
      formstate.firstName.trim().length == 0
    ) {
      updateFirstErrorData("Please enter first name");
      myRef.current.scrollIntoView();
    } else if (
      formstate.lastName.trim() === "" &&
      formstate.lastName.trim().length == 0
    ) {
      updateLastErrorData("Please enter last name");
      myRef.current.scrollIntoView();
    } else if (
      newnumber.slice(dialCode.length) === "" &&
      newnumber.slice(dialCode.length).length === 0
    ) {
      updateMobileErrorData("Please enter mobile number");
      myRef.current.scrollIntoView();
    } else if (newnumber.slice(dialCode.length).length < 8) {
      updateMobileErrorData("Please enter valid mobile number");
      myRef.current.scrollIntoView();
    } else if (
      (stateCheckedNA === false &&
        formstate.email.trim() == "" &&
        formstate.email.trim().length == 0) ||
      stateCheckedNA === "" ||
      formstate.email.trim() == null
    ) {
      updateEmailErrorData("Please enter email id");
      myRef.current.scrollIntoView();
    } else if (
      stateCheckedNA === false &&
      !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
        formstate.email.trim()
      ) &&
      formstate.email.trim() !== ""
    ) {
      updateEmailErrorData("Please enter email id in correct format");
      myRef.current.scrollIntoView();
    } else if (selectedDate == null || selectedDate == "") {
      updateDateofBirth("Please select date of birth");
      myRef.current.scrollIntoView();
    } else if (
      selectedDate == "Invalid Date" ||
      moment(selectedDate).format("yyyy") < "1899" == true ||
      moment(selectedDate).format("yyyy") > new Date().getFullYear() == true
    ) {
      updateDateofBirth("Please enter valid DOB");
      myRef.current.scrollIntoView();
    } else if (
      (vaccinedose === undefined || vaccinedose === "") &&
      getqueryString === true
    ) {
      updateErrorVaccine("Please select vaccine dose");
      myRef.current.scrollIntoView();
    } else if (
      (vaccinedose === "Yes" ||
        vaccinedose === "Booster" ||
        vaccinedose === "Third") &&
      selectedVaccine === null && process.env.REACT_APP_LAB_ID !== "113" &&
      process.env.REACT_APP_LAB_ID !== "117"
    ) {
      updateErrorVaccinedate("Please select vaccine date");
      myRef.current.scrollIntoView();
    } else if (
      (vaccinedose === "Yes" ||
        vaccinedose === "Booster" ||
        vaccinedose === "Third") &&
      VaccineType == ""
    ) {
      updateErrorVaccinetype("Please select vaccine type");
      myRef.current.scrollIntoView();
    } else if (disability == "Others" && describeDisablity.trim() == "") {
      updateErrordescribeDisablity("Please describe the disabilities ");
      myRef.current.scrollIntoView();
    } else {
      setExpanded("panel2");
      setActive1(true);
      myRef.current.scrollIntoView();
    }
  };

  const moveNext1 = () => {
    updateEducationCheck("");
    updateZip("");
    updateState("");
    updateCounty("");

    //====Validation====//
    if (ChildCheck == "Yes" && EduCheck === "") {
      updateEducationCheck("Please select the child attending");
      myRef1.current.scrollIntoView();
    } else if (state.trim() === "" && state.trim().length === 0) {
      updateState("Please select state");
    } else if (zipCode.trim() == "" && zipCode.trim().length == 0) {
      updateZip("Please enter zip code");
      myRef1.current.scrollIntoView();
    } else if (zipCode.trim().length < 5 || zipCode.trim().length > 7) {
      updateZip(
        "Zip code should be minimum 5 characters and maximum 7 characters"
      );
      myRef1.current.scrollIntoView();
    } else if (
      countyTemp.length &&
      county.trim() === "" &&
      county.trim().length === 0
    ) {
      updateCounty("Please select county");
      myRef1.current.scrollIntoView();
    } else if (
      countyTemp.length == 0 &&
      city.trim() === "" &&
      city.trim().length === 0
    ) {
      updateCounty("Please enter the City");
      myRef1.current.scrollIntoView();
    } else {
      setExpanded("panel3");
      setActive2(true);
      myRef.current.scrollIntoView();
    }
  };

  const moveNext2 = () => {
    setsymptomsError("");
    setsymptomsListError("");
    setsymptomsDateError("");
    setReceivedVaccineError("");
    setallergicToAnythingError("");
    setseriousReactionError("");
    setweakenedImmuneSystemError("");
    setconditionsCheckError("");
    settreatmentCheckError("");
    setdiagnosedCovidError("");

    //validation
    if (symptomatic == null || symptomatic == "") {
      setsymptomsError(
        "Please select if you experience any Covid -19 symptoms?"
      );
      myRef2.current.scrollIntoView();
    } else if (
      symptomatic === "Y" &&
      Object.keys(stateChecked).filter((e) => stateChecked[e] === true)
        .length === 0
    ) {
      setsymptomsListError("Please select Symptoms");
      myRef2.current.scrollIntoView();
    } else if (
      Object.keys(stateChecked).filter((e) => stateChecked[e] === true).length >
        0 &&
      selectedDateSymptom == null
    ) {
      setsymptomsDateError("Please select Symptom Date");
      myRef2.current.scrollIntoView();
    } else if (Covid19vaccine == null || Covid19vaccine == "") {
      setReceivedVaccineError(
        "Please select if you received the Covid-19 vaccine?"
      );
      myRef2.current.scrollIntoView();
    } else if (allergicToAnything == null || allergicToAnything == "") {
      setallergicToAnythingError("Please select if you allergic to Anything?");
      myRef2.current.scrollIntoView();
    } else if (seriousReaction == null || seriousReaction == "") {
      setseriousReactionError(
        "Please select if you have any serious reaction after receiving a vaccination?"
      );
      myRef2.current.scrollIntoView();
    } else if (weakenedImmuneSystem == null || weakenedImmuneSystem == "") {
      setweakenedImmuneSystemError(
        "Please select if you have weekened Immune System?"
      );
      myRef2.current.scrollIntoView();
    } else if (conditionsCheck == null || conditionsCheck == "") {
      setconditionsCheckError(
        "Please select if you have any history of seizures or neurological conditions?"
      );
      myRef2.current.scrollIntoView();
    } else if (treatmentCheck == null || treatmentCheck == "") {
      settreatmentCheckError(
        "Please select if you live with or take care of take steroids or any treatments?"
      );
      myRef2.current.scrollIntoView();
    } else if (diagnosedCovid == null || diagnosedCovid == "") {
      setdiagnosedCovidError(
        "Please select if you been diagnosed with COVID within the past 3 months?"
      );
      myRef2.current.scrollIntoView();
    } else {
      setExpanded("panel4");
      setActive3(true);
      myRef3.current.scrollIntoView();
    }
  };

  const moveNext3 = () => {
    setconsentError("");
    setparentGuardianSignatureError("");
    setconsentDateError("");

    //validation
    if (consent == null || consent == "") {
      setconsentError("Please enter your initials");
      myRef3.current.scrollIntoView();
    } else if (
      parentGuardianSignature == null ||
      parentGuardianSignature == ""
    ) {
      setparentGuardianSignatureError(
        "Please enter the Parent/Guardian signature"
      );
      myRef3.current.scrollIntoView();
    } else if (consentDate == null || consentDate == "") {
      setconsentDateError("Please enter the date");
      myRef3.current.scrollIntoView();
    } else {
      setExpanded("panel5");
      setActive4(true);
      myRef.current.scrollIntoView();
    }
  };

  //=======End of Form Next Functionalities=========//

  //=======Modal box state Open=========//
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  //=======Modal box state Close=========//
  let history = useHistory();
  const handleClose = () => {
    localStorage.clear();
    setOpen(false);
    window.location.reload();
  };

  const resetForm = () => {
    localStorage.clear();
    window.location.reload();
  };

  //=======Form Data submission=========//
  const formSubmit = (e) => {
    updateChildErrorCheck("");
    updateFirstErrorData("");
    updateLastErrorData("");
    updateMobileErrorData("");
    updateEmailErrorData("");
    updateEmailErrorData("");
    updateDateofBirth("");
    updateDateofBirth("");
    updateErrordescribeDisablity("");
    updateErrorVaccine("");
    updateErrorVaccinedate("");
    updateErrorVaccinetype("");
    updateErrorVaccinedate("");
    updateErrorVaccinetype("");

    updateEducationCheck("");
    updateZip("");
    updateState("");
    updateCounty("");

    setsymptomsError("");
    setsymptomsListError("");
    setsymptomsDateError("");
    setReceivedVaccineError("");
    setallergicToAnythingError("");
    setseriousReactionError("");
    setweakenedImmuneSystemError("");
    setconditionsCheckError("");
    settreatmentCheckError("");
    setdiagnosedCovidError("");

    setconsentError("");
    setparentGuardianSignatureError("");
    setconsentDateError("");

    if (
      (process.env.REACT_APP_LAB_ID === "113" ||
        process.env.REACT_APP_LAB_ID === "117") &&
      ChildCheck == ""
    ) {
      updateChildErrorCheck("Please select the appointment for Child");
      myRef.current.scrollIntoView();
    } else if (
      (Checkvaccinedose === undefined || Checkvaccinedose === "") &&
      getqueryString === false
    ) {
      updateErrorVaccine("Please select vaccine dose");
      myRef.current.scrollIntoView();
    } else if (
      (Checkvaccinedose === "Booster" || Checkvaccinedose === "Third") &&
      selectedVaccine == null
    ) {
      updateErrorVaccinedate("Please select vaccine date");
      myRef.current.scrollIntoView();
    } else if (
      (Checkvaccinedose === "Booster" || Checkvaccinedose === "Third") &&
      VaccineType === ""
    ) {
      updateErrorVaccinetype("Please select vaccine type");
      myRef.current.scrollIntoView();
    } else if (
      formstate.firstName.trim() == "" &&
      formstate.firstName.trim().length == 0
    ) {
      updateFirstErrorData("Please enter first name");
      myRef.current.scrollIntoView();
    } else if (
      formstate.lastName.trim() == "" &&
      formstate.lastName.trim().length == 0
    ) {
      updateLastErrorData("Please enter last name");
      myRef.current.scrollIntoView();
    } else if (
      newnumber.slice(dialCode.length) === "" &&
      newnumber.slice(dialCode.length).length === 0
    ) {
      updateMobileErrorData("Please enter mobile number");
      myRef.current.scrollIntoView();
    } else if (newnumber.slice(dialCode.length).length < 8) {
      updateMobileErrorData("Please enter valid mobile number");
      myRef.current.scrollIntoView();
    } else if (
      (stateCheckedNA === false &&
        formstate.email.trim() == "" &&
        formstate.email.trim().length == 0) ||
      stateCheckedNA === ""
    ) {
      updateEmailErrorData("Please enter email id");
      myRef.current.scrollIntoView();
    } else if (
      stateCheckedNA === false &&
      !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
        formstate.email
      ) &&
      formstate.email != ""
    ) {
      updateEmailErrorData("Please enter email id in correct format");
      myRef.current.scrollIntoView();
    } else if (selectedDate == null || selectedDate == "") {
      updateDateofBirth("Please select date of birth");
      myRef.current.scrollIntoView();
    } else if (
      selectedDate == "Invalid Date" ||
      moment(selectedDate).format("yyyy") < "1899" == true ||
      moment(selectedDate).format("yyyy") > new Date().getFullYear() == true
    ) {
      updateDateofBirth("Please enter valid DOB");
      myRef.current.scrollIntoView();
    } else if (ChildCheck == "Yes" && EduCheck === "") {
      updateEducationCheck("Please select the child attending");
      myRef1.current.scrollIntoView();
    } else if (country.trim() == "" && country.trim().length == 0) {
      updateCountry("Please select country");
      myRef1.current.scrollIntoView();
    } else if (state.trim() == "" && state.trim().length == 0) {
      updateState("Please select state");
      myRef1.current.scrollIntoView();
    } else if (zipCode.trim() == "" && zipCode.trim().length == 0) {
      updateZip("Please enter zip code");
      myRef1.current.scrollIntoView();
    } else if (zipCode.trim().length < 5 || zipCode.trim().length > 7) {
      updateZip(
        "Zip code should be minimum 5 characters and maximum 7 characters"
      );
      myRef1.current.scrollIntoView();
    } else if (
      countyTemp.length &&
      county.trim() === "" &&
      county.trim().length === 0
    ) {
      updateCounty("Please select county");
      myRef1.current.scrollIntoView();
    } else if (
      countyTemp.length == 0 &&
      city.trim() === "" &&
      city.trim().length === 0
    ) {
      updateCounty("Please enter the City");
      myRef1.current.scrollIntoView();
    } else if (
      (vaccinedose === undefined || vaccinedose === "") &&
      getqueryString === true
    ) {
      updateErrorVaccine("Please select vaccine dose");
      myRef.current.scrollIntoView();
    } else if (
      (vaccinedose === "Yes" ||
        vaccinedose === "Booster" ||
        vaccinedose === "Third") && process.env.REACT_APP_LAB_ID != "113" &&
        process.env.REACT_APP_LAB_ID != "117" &&
      selectedVaccine == null
    ) {
      updateErrorVaccinedate("Please select vaccine date");
      myRef.current.scrollIntoView();
    } else if (
      (vaccinedose === "Yes" ||
        vaccinedose === "Booster" ||
        vaccinedose === "Third") &&
      VaccineType == ""
    ) {
      updateErrorVaccinetype("Please select vaccine type");
      myRef.current.scrollIntoView();
    } else if (
      ((vaccinedose == "Yes" ||
        vaccinedose === "Booster" ||
        vaccinedose === "Third") &&
        selectedVaccine == "Invalid Date") ||
      ((vaccinedose == "Yes" ||
        vaccinedose === "Booster" ||
        vaccinedose === "Third") &&
        moment(selectedVaccine).format("yyyy") < "1899" == true) ||
      (vaccinedose == "Yes" &&
        moment(selectedVaccine).format("yyyy") > new Date().getFullYear() ==
          true)
    ) {
      updateErrorVaccinedate("Please enter valid vaccine date");
      myRef.current.scrollIntoView();
    } else if (disability == "Others" && describeDisablity.trim() == "") {
      updateErrordescribeDisablity("Please describe the disabilities ");
      myRef.current.scrollIntoView();
    } else if (
      (process.env.REACT_APP_LAB_ID === "113" ||
        process.env.REACT_APP_LAB_ID === "117") &&
      ChildCheck === "Yes"
    ) {
      if (symptomatic == null || symptomatic == "") {
        setsymptomsError(
          "Please select if you experience any Covid -19 symptoms?"
        );
        myRef2.current.scrollIntoView();
      } else if (
        symptomatic === "Y" &&
        Object.keys(stateChecked).filter((e) => stateChecked[e] === true)
          .length === 0
      ) {
        setsymptomsListError("Please select Symptoms");
        myRef2.current.scrollIntoView();
      } else if (
        Object.keys(stateChecked).filter((e) => stateChecked[e] === true)
          .length > 0 &&
        selectedDateSymptom == null
      ) {
        setsymptomsDateError("Please select Symptom Date");
        myRef2.current.scrollIntoView();
      } else if (Covid19vaccine == null || Covid19vaccine == "") {
        setReceivedVaccineError(
          "Please select if you received the Covid-19 vaccine?"
        );
        myRef2.current.scrollIntoView();
      } else if (allergicToAnything == null || allergicToAnything == "") {
        setallergicToAnythingError(
          "Please select if you allergic to Anything?"
        );
        myRef2.current.scrollIntoView();
      } else if (seriousReaction == null || seriousReaction == "") {
        setseriousReactionError(
          "Please select if you have any serious reaction after receiving a vaccination?"
        );
        myRef2.current.scrollIntoView();
      } else if (weakenedImmuneSystem == null || weakenedImmuneSystem == "") {
        setweakenedImmuneSystemError(
          "Please select if you have weekened Immune System?"
        );
        myRef2.current.scrollIntoView();
      } else if (conditionsCheck == null || conditionsCheck == "") {
        setconditionsCheckError(
          "Please select if you have any history of seizures or neurological conditions?"
        );
        myRef2.current.scrollIntoView();
      } else if (treatmentCheck == null || treatmentCheck == "") {
        settreatmentCheckError(
          "Please select if you live with or take care of take steroids or any treatments?"
        );
        myRef2.current.scrollIntoView();
      } else if (diagnosedCovid == null || diagnosedCovid == "") {
        setdiagnosedCovidError(
          "Please select if you been diagnosed with COVID within the past 3 months?"
        );
        myRef2.current.scrollIntoView();
      } else if (consent == null || consent == "") {
        setconsentError("Please enter your initials");
        myRef3.current.scrollIntoView();
      } else if (
        parentGuardianSignature == null ||
        parentGuardianSignature == ""
      ) {
        setparentGuardianSignatureError(
          "Please enter the Parent/Guardian signature"
        );
        myRef3.current.scrollIntoView();
      } else if (consentDate == null || consentDate == "") {
        setconsentDateError("Please enter the date");
        myRef3.current.scrollIntoView();
      } else {
        console.log("----Child Check Yes API Call-----");
        if (
          formstate.firstName.trim() !== "" &&
          formstate.lastName.trim() !== "" &&
          newnumber !== "" &&
          selectedDate !== null &&
          selectedDate != "Invalid Date" &&
          moment(selectedDate).format("yyyy") < "1899" == false &&
          selectedDate != "Invalid Date" &&
          vaccinedose != undefined &&
          selectedVaccine != "Invalid Date" &&
          moment(selectedVaccine).format("yyyy") < "1899" == false &&
          moment(selectedVaccine).format("yyyy") > new Date().getFullYear() ==
            false &&
          moment(selectedDate).format("yyyy") > new Date().getFullYear() ==
            false
        ) {
          if (
            selectedVaccine == "Invalid Date" ||
            moment(selectedVaccine).format("yyyy") < "1899" == true ||
            moment(selectedDate).format("yyyy") > new Date().getFullYear() ==
              true
          ) {
            return 0;
          } else {
            if (disability == "Others" && describeDisablity.trim() != "") {
              SaveApi();
              return;
            } else if (
              (vaccinedose === "Yes" ||
                vaccinedose === "Booster" ||
                vaccinedose === "Third" ||
                Checkvaccinedose === "Booster" ||
                Checkvaccinedose === "Third") &&
              selectedVaccine != null &&
              VaccineType != ""
            ) {
              SaveApi();
              return;
            } else if (vaccinedose === "No") {
              SaveApi();
              return;
            }
            else if(Checkvaccinedose==="Yes"&&(process.env.REACT_APP_LAB_ID === "113" || process.env.REACT_APP_LAB_ID === "117")){
              SaveApi();
              return;
            }
          }
        }
      }
    } else {
      console.log("=======Child Check No & Normal value API call");
      if (
        formstate.firstName.trim() !== "" &&
        formstate.lastName.trim() !== "" &&
        newnumber !== "" &&
        zipCode.trim() !== "" &&
        selectedDate !== null &&
        selectedDate != "Invalid Date" &&
        moment(selectedDate).format("yyyy") < "1899" == false &&
        selectedDate != "Invalid Date" &&
        vaccinedose != undefined &&
        selectedVaccine != "Invalid Date" &&
        moment(selectedVaccine).format("yyyy") < "1899" == false &&
        moment(selectedVaccine).format("yyyy") > new Date().getFullYear() ==
          false &&
        moment(selectedDate).format("yyyy") > new Date().getFullYear() == false
      ) {
        if (
          selectedVaccine == "Invalid Date" ||
          moment(selectedVaccine).format("yyyy") < "1899" == true ||
          moment(selectedDate).format("yyyy") > new Date().getFullYear() == true
        ) {
          return 0;
        } else {
          if (disability == "Others" && describeDisablity.trim() != "") {
            SaveApi();
            return;
          } else if (
            (vaccinedose === "Yes" ||
              vaccinedose === "Booster" ||
              vaccinedose === "Third" ||
              Checkvaccinedose === "Booster" ||
              Checkvaccinedose === "Third") &&
            selectedVaccine != null &&
            VaccineType != ""
          ) {
            SaveApi();
            return;
          } else if (vaccinedose === "No") {
            SaveApi();
            return;
          }
        }
      }
    }
  };

  //=========API Call Function=========//
  const SaveApi = (e) => {
    alertbtn(formstate.firstName.trim(),formstate.lastName.trim(),selectedDate,newnumber,formstate.email);
  };

  //===Pop Up modal functionalities===//
  const alertbtn = (Fname, Lname, DOB, Phone, Email) => {
    confirmAlert({
      title: 'Please confirm your details',
      message: `Please confirm that your information is correct. Your name and date of birth should be exactly as it appears on your photo ID. Also confirm your phone number and email as this information will be in your vaccine appointment details.`,
      childrenElement: () => <div style={{ display: "flex", flexDirection: "column", margin: "5px 0px" }}>
        <div style={{ margin: "5px 0px" }}>First Name:<span className="Status_name">{Fname}</span></div>
        <div style={{ margin: "5px 0px" }}>Last Name:<span className="Status_name">{Lname}</span></div>
        <div style={{ margin: "5px 0px" }}>DOB (MMM DD, YYYY):<span className="Status_name">{moment(DOB).format("MMM DD, YYYY")}</span></div>
        <div className="modalPhoneNumber" style={{ margin: "-3px 0px", display: "flex", alignItems: "center" }}><div>Phone:</div>
          <PhoneInput
            country={"us"}
            disabled={true}
            required
            name="mobileNumber"
            disableDropdown={true}
            value={Phone}
          /></div>
        <div style={{ margin: "5px 0px" }}>Email:<span className="Status_name">{Email ? Email : "N/A"}</span></div>
        <div style={{ margin: "5px 0px" }}>By clicking confirm, you agree to receiving a text/email notification with your appointment confirmation details.</div>
      </div>,
      buttons: [
        {
          label: 'Confirm',
          onClick: () => confirmfun()
        },
        {
          label: 'Cancel',
          onClick: () => cancelfun()
        }
      ],
    })
  };

  const confirmfun = () => {
    var adate = moment(selectedDate).format("DD");
    var amonth = moment(selectedDate).format("MM");
    var ayear = moment(selectedDate).format("yyyy");
    var age = 16;
    var mydate = new Date();
    mydate.setFullYear(ayear, amonth, adate);
    var currdate = new Date();
    currdate.setFullYear(currdate.getFullYear() - age);
    if (currdate - mydate < 0) {
      setAgelimit(true);
      localStorage.setItem("getAgelimit", true);
    }
    localStorage.setItem("vaccinedose", vaccinedose);
    let splitstate = state.split(",");
    // console.log("redtest",Checkvaccinedose,vaccinedose, ChildCheck);
    let data = {
      //misc
      patientId: 0,
      fromWeb: true,

      //first accordion
      appointmentForChild: ChildCheck,
      state: splitstate[0],
      firstName: formstate.firstName.trim(),
      middleName: formstate.middleName,
      lastName: formstate.lastName.trim(),
      mobileNumber: newnumber.includes("+") ? newnumber : "+" + newnumber,
      phoneCode: "+" + dialCode,
      email: stateCheckedNA === true ? "N/A" : formstate.email.trim(),
      dob:
        selectedDate == null ? null : moment(selectedDate).format("yyyy-MM-DD"),
      comments: comments.trim().replaceAll("''","'"),
      //second accordion
      gender: gender,
      addressTwo: addressTwo.trim() == "" ? "" : addressTwo.trim().replaceAll("''","'"),
      addressOne: addressOne.trim() == "" ? "" : addressOne.trim().replaceAll("''","'"),
      county: county === "" ? null : county,
      country: country,
      zipCode: zipCode == "" ? "" : zipCode.trim(),
      city: countyTemp.length === 0 ? city.trim() : county,
      ethnicity: ethnicity == "" ? "" : ethnicity,
      prioritizedGroup: ChildCheck == "Yes" ? EduCheck : member,
      race: racedSelect == "" ? "" : racedSelect,
      sendMessageToMobile: sendMessageToMobileCheck === true ? true : false,

      //third accordion
      symptomaticDate:
        selectedDateSymptom == null
          ? null
          : moment(selectedDateSymptom).format("yyyy-MM-DD"),

      pregnant: gender == "Female" ? pregnancy : "U",
      vaccineSymptoms:
        Object.keys(stateChecked).filter((e) => stateChecked[e] === true)
          .length == 0
          ? null
          : Object.keys(stateChecked)
              .filter((e) => stateChecked[e] === true)
              .map((index) =>
                index.replaceAll("_", " ").replaceAll("Fever", "Fever>100.4")
              ),
      disability: disability,
      disabilityOthers:
        describeDisablity !== undefined && describeDisablity !== null
          ? describeDisablity.trim().replaceAll("''","'")
          : "",
      vaccineType:
        vaccinedose === "Yes" || Checkvaccinedose === "Yes"
          ? "second dose"
          : vaccinedose === "Booster" || Checkvaccinedose === "Booster"
          ? "booster"
          : vaccinedose === "Third" || Checkvaccinedose === "Third"
          ? "third dose"
          : "first dose",
      isPreviouslyReceivedVaccineDose:
        ChildCheck === "Yes"
          ? Checkvaccinedose
          : vaccinedose === "No"
          ? "No"
          : vaccinedose === "Booster"
          ? "Booster"
          : "Yes",
      previousDoseDate:
        vaccinedose === "Yes" ||
        vaccinedose === "Booster" ||
        vaccinedose === "Third" ||
        Checkvaccinedose === "Booster" ||
        Checkvaccinedose === "Yes" ||
        Checkvaccinedose === "Third"
          ? moment(selectedVaccine).format("yyyy-MM-DD")
          : null,
      previousDoseType: VaccineType === "" ? null : VaccineType,
      createdIn:
        new URLSearchParams(window.location.search).get("type") == "internal"
          ? "Vaccination-Internal"
          : new URLSearchParams(window.location.search).get("type") == "mobile"
          ? "Vaccination-Mobile"
          : "Vaccination",

      //Third Accordion
      primaryMemberId: primaryCarrierId ? primaryCarrierId.trim() : "",
      primaryGroupId: primaryGroupId ? primaryGroupId.trim() : "",
      primaryInsuranceCompanyName: primaryInsuranceCompanyName
        ? primaryInsuranceCompanyName.trim()
        : "",
      secondaryMemberId: secondaryCarrierId ? secondaryCarrierId.trim() : "",
      secondaryGroupId: secondaryGroupId ? secondaryGroupId.trim() : "",
      secondaryInsuranceCompanyName: secondaryInsuranceCompanyName
        ? secondaryInsuranceCompanyName.trim()
        : "",
      allergicToAnything: allergicToAnything,
      seriousReactionAfterVaccination: seriousReaction,
      weakenedImmuneSystem: weakenedImmuneSystem,
      seizuresOrNeurologicalCondition: conditionsCheck,
      steroidOrAntiCancerDrugOrXray: treatmentCheck,
      diagnosedCovid: diagnosedCovid,

      //Fourth Accordion
      consent: consent ? consent.trim() : "",
      parentGuardianSignature: parentGuardianSignature
        ? parentGuardianSignature.trim()
        : "",
      consentDate:
        consentDate == null ? null : moment(consentDate).format("yyyy-MM-DD"),
    };

    localStorage.setItem("Array", JSON.stringify(data));
    localStorage.setItem("State", state);
    localStorage.setItem("SymptomsCheck", symptomatic);
    localStorage.setItem("newstateChecked", newstateChecked);
    localStorage.setItem(
      "internal",
      new URLSearchParams(window.location.search).get("type") == "internal"
    );
    localStorage.setItem(
      "mobile",
      new URLSearchParams(window.location.search).get("type") == "mobile"
    );
    console.log("state send", data);
    history.push({
      pathname: "/calendlyview",
      state: data,
    });

  }
  const cancelfun = () => {
    console.log("cancel clicked")
  }



  //==Component Did Mount==//
  useEffect(() => {
    CheckqueryString(
      new URLSearchParams(window.location.search).get("type") == "internal" ||
        new URLSearchParams(window.location.search).get("type") == "mobile"
    );
    var url = window.location.search.substring(19);
    if (new URLSearchParams(window.location.search).get("id")) {
      var getQuery = new URLSearchParams(window.location.search).get("id");
      var decodeurl = JSON.parse(window.atob(getQuery));
      console.log("if", decodeurl);

      setformState({
        //first accordion
        firstName: decodeurl.patientFirstName,
        lastName: decodeurl.patientLastName,
        email: decodeurl.patientEmail == null ? "" : decodeurl.patientEmail,

        middleName: "",
      });

      setdescribeDisablity("");
      setcomments("");
      setoccupation("");
      setzipCode("");
      setCity("");
      setaddressOne("");
      setaddressTwo("");
      setNumber(decodeurl.patientMobileNumber);
      setSelectedDate(decodeurl.patientDob);
      setStateCheckNA(decodeurl.patientEmail == "N/A" ? true : false);
      Setvaccinedose(decodeurl.previousReceivedVaccineDose);
      setSelectedVaccine(decodeurl.previousReceivedVaccineDate);
      setVaccineType(
        decodeurl.previousReceivedVaccineType == null
          ? ""
          : decodeurl.previousReceivedVaccineType.slice(0, 10) == "Pfizer-Bio"
          ? "Pfizer-BioNTech"
          : decodeurl.previousReceivedVaccineType.slice(0, 6) == "Moderna"
          ? "Moderna"
          : decodeurl.previousReceivedVaccineType.slice(0, 10) == "I’m not su"
          ? "Moderna"
          : ""
      );
    } else if (url) {
      var URLdecode = JSON.parse(window.atob(url));
      console.log("else if", URLdecode);
      setformState({
        //first accordion
        firstName: URLdecode.patientFirstName,
        lastName: URLdecode.patientLastName,
        email: decodeurl.patientEmail == null ? "" : decodeurl.patientEmail,
      });
      setNumber(URLdecode.patientMobileNumber);
      setSelectedDate(URLdecode.patientDob);
      setStateCheckNA(URLdecode.patientEmail == null ? true : false);
      Setvaccinedose(URLdecode.previousReceivedVaccineDose);
      setSelectedVaccine(URLdecode.previousReceivedVaccineDate);
      setVaccineType(URLdecode.previousReceivedVaccineType);
    }

    window.addEventListener("load", (event) => {
      localStorage.clear();
    });

    getRaces();
    GetCountryStateList();
    if (localStorage.getItem("Array")) {
      var setlocal = JSON.parse(localStorage.getItem("Array"));
      var symptomaticCheck = localStorage.getItem("SymptomsCheck");
      var newstateCheckedCheck = JSON.parse(
        localStorage.getItem("newstateChecked")
      );
      console.log("setLocal", setlocal);
      setformState({
        //first accordion
        firstName: setlocal.firstName,
        middleName: setlocal.middleName,
        lastName: setlocal.lastName,
        email: setlocal.email,
      });
      setCity(setlocal.city);
      setdescribeDisablity(setlocal.disabilityOthers);
      setcomments(setlocal.comments);
      setoccupation(setlocal.occupation);
      setzipCode(setlocal.zipCode);
      setaddressOne(setlocal.addressOne);
      setaddressTwo(setlocal.addressTwo);
      setChildCheck(setlocal.appointmentForChild);
      newsetStateCheck(newstateCheckedCheck);
      setprimaryInsuranceCompanyName(setlocal.primaryInsuranceCompanyName);
      setprimaryCarrierId(setlocal.primaryMemberId);
      setprimaryGroupId(setlocal.primaryGroupId);
      setsecondaryInsuranceCompanyName(setlocal.secondaryInsuranceCompanyName);
      setsecondaryCarrierId(setlocal.secondaryMemberId);
      setsecondaryGroupId(setlocal.secondaryGroupId);

      if (
        setlocal.vaccineSymptoms !== null &&
        setlocal.vaccineSymptoms.length !== 0
      ) {
        let symptomvalue = setlocal.vaccineSymptoms.map((item) => {
          return item.replaceAll(" ", "_").replaceAll("Fever>100.4", "Fever");
        });
        var result = {};
        symptomvalue.forEach((prop) => (result[prop] = true));
        setStateCheck(result);
      }
      setCountry(setlocal.country);
      setCounty(setlocal.county);
      // setState(setlocal.state);
      setSymptomatic(symptomaticCheck);
      setSelectedDateSymptom(setlocal.symptomaticDate);
      setNumber(setlocal.mobileNumber);
      setSelectedDate(setlocal.dob);
      // Setvaccinedose(setlocal.isPreviouslyReceivedVaccineDose);
      Setvaccinedose(
        setlocal.vaccineType === "third dose"
          ? "Third"
          : setlocal.vaccineType === "booster"
          ? "Booster"
          : setlocal.vaccineType === "second dose"
          ? "Yes"
          : "No"
      );
      // setCheckvaccinedose(setlocal.isPreviouslyReceivedVaccineDose);
      setCheckvaccinedose(
        setlocal.vaccineType === "third dose"
          ? "Third"
          : setlocal.vaccineType === "booster"
          ? "Booster"
          :setlocal.vaccineType === "second dose"
          ?"Yes"
          : "No"
      );
      setsendMessageToMobileCheck(setlocal.sendMessageToMobile);
      setStateCheckNA(setlocal.email == "N/A" ? true : false);
      setVaccineType(setlocal.vaccineType);
      setGender(setlocal.gender);
      setEthnicity(setlocal.ethnicity);
      setState(localStorage.getItem("State"));
      setRacedSelect(setlocal.race);
      setVaccineType(setlocal.previousDoseType);
      setSelectedVaccine(setlocal.vaccinedate);
      setPregnancy(setlocal.pregnant);
      setdisability(setlocal.disability);
      setMember(setlocal.prioritizedGroup);
      setEduCheck(setlocal.prioritizedGroup);
      setSelectedVaccine(setlocal.previousDoseDate);
      setCovid19vaccine(setlocal.isPreviouslyReceivedVaccineDose);
      setallergicToAnything(setlocal.allergicToAnything);
      setseriousReaction(setlocal.seriousReactionAfterVaccination);
      setweakenedImmuneSystem(setlocal.weakenedImmuneSystem);
      setconditionsCheck(setlocal.seizuresOrNeurologicalCondition);
      settreatmentCheck(setlocal.steroidOrAntiCancerDrugOrXray);
      setdiagnosedCovid(setlocal.diagnosedCovid);
      setconsent(setlocal.consent);
      setparentGuardianSignature(setlocal.parentGuardianSignature);
      setconsentDate(setlocal.consentDate);
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <Header />
      <RegisterBanner />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className="paperModal1">
          <div className="closeHolder" onClick={handleClose}>
            <img src={close} alt="closeIcon" />
          </div>

          <div className="successmsg" style={{ marginTop: "60px" }}>
            <h3>
              {" "}
              Please call the hotline to schedule your second dose at (340)
              777-VACS (8227) <br></br>
              8:30 am until 5:00 pm, Monday through Friday.
            </h3>
          </div>
        </div>
      </Modal>
      {isLoaded == true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : (
        <div className="accordionHolder">
          <h3 style={{ padding: "0px 23px 0px 32px" }}>
            Please only book one appointment per person. If multiple times are
            booked per person, your last appointment will be the final confirmed
            booking, and prior appointment times will be released to the
            community.
          </h3>
          {process.env.REACT_APP_LAB_ID === "14" ||
          process.env.REACT_APP_LAB_ID === "15" ? (
            <p style={{ padding: "0px 23px 0px 32px" }}>
              Notice: Appointments for patients ages 5-11 need to be scheduled
              by phone. Please call 340-777-8227 to schedule an appointment for
              patients in this age range.
            </p>
          ) : null}

          <Accordion
            square
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className="mainer"
          >
            <div className="accordionbg">
              <div className="accSummary">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography ref={myRef}>
                    General Information *
                    <FormHelperText style={{ color: "red" }}>
                      <span>{errorFirstData !== "" ? errorFirstData : ""}</span>
                      <span>
                        {errorChildCheck !== "" ? errorChildCheck : ""}
                      </span>
                      <span> {errorLastData ? errorLastData : ""}</span>
                      <span> {errorMobileData ? errorMobileData : ""}</span>
                      <span> {errorEmailData ? errorEmailData : ""}</span>
                      <span> {errorDateofbirth ? errorDateofbirth : ""}</span>
                      <span> {errorVaccine ? errorVaccine : ""}</span>
                      <span> {errorCVCmonth ? errorCVCmonth : ""}</span>
                      <span> {errorVaccinedate ? errorVaccinedate : ""}</span>
                      <span> {errorVaccinetype ? errorVaccinetype : ""}</span>
                      <span> {errordisability ? errordisability : ""}</span>
                      <span>
                        {" "}
                        {errordescribedisability ? errordescribedisability : ""}
                      </span>
                    </FormHelperText>
                  </Typography>
                </AccordionSummary>
              </div>
            </div>
            <AccordionDetails>
              <Typography style={{ width: "100%" }}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={12} sm={12}>
                    {process.env.REACT_APP_LAB_ID === "113" ||
                    process.env.REACT_APP_LAB_ID === "117" ? (
                      <>
                        {/* <FormLabel component="legend" className="paddingAdj">
                          Are you scheduling an appointment for someone between
                          5-11 years old? *
                        </FormLabel>
                        <RadioGroup
                          aria-label="ChildCheck"
                          name="ChildCheck"
                          value={ChildCheck}
                          onChange={onChangeChildCheck}
                        >
                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                          </Grid>
                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </Grid>
                        </RadioGroup> */}
                      </>
                    ) : null}
                  </Grid>
                  {getqueryString == false ? (
                    <>
                      {process.env.REACT_APP_LAB_ID === "113" ||
                      process.env.REACT_APP_LAB_ID === "117" ? (
                        <>
                          {/* {ChildCheck == "Yes" ? (
                            <Grid item xs={12} md={12} sm={12}>
                              <FormLabel
                                component="legend"
                                className="paddingAdj"
                              >
                                Let's get started. Which dose are you looking to
                                schedule? *
                              </FormLabel>
                              <RadioGroup
                                aria-label="vaccinedose"
                                name="vaccinedose"
                                value={Checkvaccinedose}
                                onChange={Checkhandlevaccinedose}
                              >
                                <Grid item xs={12} md={3} sm={12}>
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="First shot"
                                  />
                                </Grid>
                                <Grid item xs={12} md={3} sm={12}>
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="Second shot"
                                  />
                                </Grid>
                              </RadioGroup>
                            </Grid>
                          ) : ChildCheck == "No" ? ( */}
                            <Grid item xs={12} md={12} sm={12}>
                              <FormLabel
                                component="legend"
                                className="paddingAdj"
                              >
                                Let's get started. Which dose are you looking to
                                schedule? *
                              </FormLabel>
                              <RadioGroup
                                aria-label="vaccinedose"
                                name="vaccinedose"
                                value={Checkvaccinedose}
                                onChange={Checkhandlevaccinedose}
                              >
                                <Grid item xs={12} md={3} sm={12}>
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="My first shot"
                                  />
                                </Grid>
                                <Grid item xs={12} md={3} sm={12}>
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="My second shot (Pfizer or Moderna)"
                                  />
                                </Grid>
                                <Grid item xs={12} md={3} sm={12}>
                                  <FormControlLabel
                                    value="Third"
                                    control={<Radio />}
                                    label="My Third shot (Pfizer or Moderna)"
                                  />
                                </Grid>
                                <Grid item xs={12} md={3} sm={12}>
                                  <FormControlLabel
                                    value="Booster"
                                    control={<Radio />}
                                    label="Booster (Pfizer, Moderna or Janssen)"
                                  />
                                </Grid>
                              </RadioGroup>
                            </Grid>
                          {/* ) : null} */}
                        </>
                      ) : (
                        <Grid item xs={12} md={12} sm={12}>
                          <FormLabel component="legend" className="paddingAdj">
                            Let's get started. Which dose are you looking to
                            schedule? *
                          </FormLabel>
                          <RadioGroup
                            aria-label="vaccinedose"
                            name="vaccinedose"
                            value={Checkvaccinedose}
                            onChange={Checkhandlevaccinedose}
                          >
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                value="No"
                                control={<Radio />}
                                label="My first shot"
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                value="Yes"
                                control={<Radio />}
                                label="My second shot (Pfizer or Moderna)"
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                value="Third"
                                control={<Radio />}
                                label="My Third shot (Pfizer or Moderna)"
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                value="Booster"
                                control={<Radio />}
                                label="Booster (Pfizer, Moderna and Janssen)"
                              />
                            </Grid>
                          </RadioGroup>
                        </Grid>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {Checkvaccinedose === "Third" && getqueryString === false ? (
                    <>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item xs={12} md={6} sm={12}>
                          <KeyboardDatePicker
                            className="form-date"
                            clearable
                            style={{ width: "100%" }}
                            value={selectedVaccine}
                            onChange={(date) => handleDateChangeVaccine(date)}
                            format="MM/dd/yyyy"
                            label="COVID-19 vaccine date *"
                            maxDate={new Date()}
                            autoOk
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControl
                          className={classes.formControl}
                          style={{ width: "100%" }}
                        >
                          <InputLabel>Vaccine Type *</InputLabel>
                          <Select
                            className="form-dropdown"
                            value={VaccineType}
                            onChange={handleChangeVaccineType}
                            name="VaccineType"
                          >
                            <MenuItem value="Pfizer-BioNTech">
                              {" "}
                              Pfizer-BioNTech{" "}
                            </MenuItem>
                            <MenuItem value="Moderna"> Moderna </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  ) : null}
                  {Checkvaccinedose === "Booster" &&
                  getqueryString === false ? (
                    <>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sm={12}
                          // style={{ marginTop: "1em" }}
                        >
                          <KeyboardDatePicker
                            className="form-date"
                            clearable
                            style={{ width: "100%" }}
                            value={selectedVaccine}
                            onChange={(date) => handleDateChangeVaccine(date)}
                            format="MM/dd/yyyy"
                            label="COVID-19 vaccine date *"
                            maxDate={new Date()}
                            autoOk
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControl
                          className={classes.formControl}
                          style={{ width: "100%" }}
                        >
                          <InputLabel>Vaccine Type *</InputLabel>
                          <Select
                            className="form-dropdown"
                            value={VaccineType}
                            onChange={handleChangeVaccineType}
                            name="VaccineType"
                          >
                            <MenuItem value="Pfizer-BioNTech">
                              {" "}
                              Pfizer-BioNTech{" "}
                            </MenuItem>
                            <MenuItem value="Moderna"> Moderna </MenuItem>
                            <MenuItem value="Janssen">Janssen</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  ) : null}
                  <Grid item xs={12} md={4} sm={12}>
                    <TextField
                      id="standard-basic1"
                      label="First Name "
                      autoComplete="off"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      required
                      onChange={handleChangeForm}
                      name="firstName"
                      value={formstate.firstName}
                      inputProps={{ maxLength: 40 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} sm={12}>
                    <TextField
                      id="standard-basic2"
                      label="Middle Initial"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      onChange={handleChangeForm}
                      name="middleName"
                      autoComplete="new-password"
                      value={formstate.middleName}
                      inputProps={{ maxLength: 40 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} sm={12}>
                    <TextField
                      id="standard-basic3"
                      label="Last Name"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      required
                      onChange={handleChangeForm}
                      name="lastName"
                      autoComplete="new-password"
                      value={formstate.lastName}
                      inputProps={{ maxLength: 40 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                      <FormLabel component="legend" className="paddingAdj">
                        Please share your contact information. Your email and phone will also be where you will receive an invitation to view your appointment details.
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} md={7} sm={12}>
                    {process.env.REACT_APP_LAB_ID === "113" ||
                    process.env.REACT_APP_LAB_ID === "117" ? (
                      <InputLabel style={{ paddingBottom: "0.3em" }}>
                        Telephone Number*
                      </InputLabel>
                    ) : (
                      <InputLabel style={{ paddingBottom: "0.3em" }}>
                        Phone Number*
                      </InputLabel>
                    )}
                    <PhoneInput
                      country={"us"}
                      // onlyCountries={["us"]}
                      // disableDropdown={true}
                      onChange={mobileNumberChange}
                      value={newnumber}
                      inputProps={{
                        autoFormat: true,
                        countryCodeEditable: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={5} sm={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sendMessageToMobileCheck}
                          onChange={handleChangeMobileCheck}
                          name="N/A"
                          value="N/A"
                        />
                      }
                      label="This number can receive text messages"
                      style={{ marginTop: "13px" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={7} sm={12}>
                    <TextField
                      id="standard-basic4"
                      label="Email"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      required
                      onChange={handleChangeForm}
                      name="email"
                      autoComplete="new-password"
                      value={stateCheckedNA === true ? "N/A" : formstate.email}
                      inputProps={{ maxLength: 150 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={5} sm={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={stateCheckedNA}
                          onChange={handleChangeNA}
                          name="N/A"
                          value="N/A"
                        />
                      }
                      label="N/A - Not Applicable"
                      style={{ marginTop: "13px" }}
                    />
                  </Grid>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={12} md={12} sm={12}>
                      <KeyboardDatePicker
                        className="form-date"
                        style={{ width: "100%" }}
                        clearable
                        value={selectedDate}
                        onChange={(date) => handleDateChange(date)}
                        format="MM/dd/yyyy"
                        label="Date of Birth"
                        maxDate={new Date()}
                        required
                        autoOk
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                  {getqueryString == true ? (
                    <>
                      {process.env.REACT_APP_LAB_ID === "113" ||
                      process.env.REACT_APP_LAB_ID === "117" ? (
                        <>
                          {ChildCheck == "Yes" ? (
                            <Grid item xs={12} md={12} sm={12}>
                              <FormLabel
                                component="legend"
                                className="paddingAdj"
                              >
                                Let's get started. Which dose are you looking to
                                schedule? *
                              </FormLabel>
                              <RadioGroup
                                aria-label="vaccinedose"
                                name="vaccinedose"
                                value={vaccinedose}
                                onChange={handlevaccinedose}
                              >
                                <Grid item xs={12} md={3} sm={12}>
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="My first shot"
                                  />
                                </Grid>
                                <Grid item xs={12} md={3} sm={12}>
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="My second shot (Pfizer or Moderna)"
                                  />
                                </Grid>
                              </RadioGroup>
                            </Grid>
                          ) : ChildCheck == "No" ? (
                            <Grid item xs={12} md={12} sm={12}>
                              <FormLabel
                                component="legend"
                                className="paddingAdj"
                              >
                                Let's get started. Which dose are you looking to
                                schedule? *
                              </FormLabel>
                              <RadioGroup
                                aria-label="vaccinedose"
                                name="vaccinedose"
                                value={vaccinedose}
                                onChange={handlevaccinedose}
                              >
                                <Grid item xs={12} md={3} sm={12}>
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="My first shot"
                                  />
                                </Grid>
                                <Grid item xs={12} md={3} sm={12}>
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="My second shot (Pfizer or Moderna)"
                                  />
                                </Grid>
                                <Grid item xs={12} md={3} sm={12}>
                                  <FormControlLabel
                                    value="Third"
                                    control={<Radio />}
                                    label="My Third shot (Pfizer or Moderna)"
                                  />
                                </Grid>
                                <Grid item xs={12} md={3} sm={12}>
                                  <FormControlLabel
                                    value="Booster"
                                    control={<Radio />}
                                    label="Booster (Pfizer, Moderna and Janssen)"
                                  />
                                </Grid>
                              </RadioGroup>
                            </Grid>
                          ) : null}
                        </>
                      ) : (
                        <Grid item xs={12} md={12} sm={12}>
                          <FormLabel component="legend" className="paddingAdj">
                            Let's get started. Which dose are you looking to
                            schedule? *
                          </FormLabel>
                          <RadioGroup
                            aria-label="vaccinedose"
                            name="vaccinedose"
                            value={vaccinedose}
                            onChange={handlevaccinedose}
                          >
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                value="No"
                                control={<Radio />}
                                label="My first shot"
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                value="Yes"
                                control={<Radio />}
                                label="My second shot (Pfizer or Moderna)"
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                value="Third"
                                control={<Radio />}
                                label="My Third shot (Pfizer or Moderna)"
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                value="Booster"
                                control={<Radio />}
                                label="Booster (Pfizer, Moderna and Janssen)"
                              />
                            </Grid>
                          </RadioGroup>
                        </Grid>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {(vaccinedose === "Yes" && getqueryString === true)||(Checkvaccinedose === "Yes" && (process.env.REACT_APP_LAB_ID === "113" ||
  process.env.REACT_APP_LAB_ID === "117")) ? (
                    <Grid container spacing={3} style={{ padding: "0px 12px" }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sm={12}
                          // style={{ marginTop: "1em" }}
                        >
                          <KeyboardDatePicker
                            clearable
                            className="form-date"
                            style={{ width: "100%" }}
                            value={selectedVaccine}
                            onChange={(date) => handleDateChangeVaccine(date)}
                            format="MM/dd/yyyy"
                            label="Last COVID-19 vaccine date *"
                            maxDate={new Date()}
                            autoOk
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControl
                          className={classes.formControl}
                          style={{ width: "100%" }}
                        >
                          <InputLabel>Vaccine Type *</InputLabel>
                          <Select
                            className="form-dropdown"
                            value={VaccineType}
                            onChange={handleChangeVaccineType}
                            name="VaccineType"
                          >
                            <MenuItem value="Pfizer-BioNTech">
                              {" "}
                              Pfizer-BioNTech{" "}
                            </MenuItem>
                            <MenuItem value="Moderna"> Moderna </MenuItem>
                            <MenuItem value=" I’m not sure">
                              {" "}
                              I’m not sure{" "}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  ) : null}
                  {vaccinedose === "Third" && getqueryString === true ? (
                    <>
                      <Grid
                        container
                        spacing={3}
                        style={{ padding: "0px 12px" }}
                      >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sm={12}
                            // style={{ marginTop: "1em" }}
                          >
                            <KeyboardDatePicker
                              className="form-date"
                              clearable
                              style={{ width: "100%" }}
                              value={selectedVaccine}
                              onChange={(date) => handleDateChangeVaccine(date)}
                              format="MM/dd/yyyy"
                              label="Last COVID-19 vaccine date *"
                              maxDate={new Date()}
                              autoOk
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12} md={6} sm={12}>
                          <FormControl
                            className={classes.formControl}
                            style={{ width: "100%" }}
                          >
                            <InputLabel>Vaccine Type *</InputLabel>
                            <Select
                              className="form-dropdown"
                              value={VaccineType}
                              onChange={handleChangeVaccineType}
                              name="VaccineType"
                            >
                              <MenuItem value="Pfizer-BioNTech">
                                {" "}
                                Pfizer-BioNTech{" "}
                              </MenuItem>
                              <MenuItem value="Moderna"> Moderna </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                  {vaccinedose === "Booster" && getqueryString === true ? (
                    <>
                      <Grid
                        container
                        spacing={3}
                        style={{ padding: "0px 12px" }}
                      >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sm={12}
                            // style={{ marginTop: "1em" }}
                          >
                            <KeyboardDatePicker
                              className="form-date"
                              clearable
                              style={{ width: "100%" }}
                              value={selectedVaccine}
                              onChange={(date) => handleDateChangeVaccine(date)}
                              format="MM/dd/yyyy"
                              label="Last COVID-19 vaccine date *"
                              maxDate={new Date()}
                              autoOk
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12} md={6} sm={12}>
                          <FormControl
                            className={classes.formControl}
                            style={{ width: "100%" }}
                          >
                            <InputLabel>Vaccine Type *</InputLabel>
                            <Select
                              className="form-dropdown"
                              value={VaccineType}
                              onChange={handleChangeVaccineType}
                              name="VaccineType"
                            >
                              <MenuItem value="Pfizer-BioNTech">
                                {" "}
                                Pfizer-BioNTech{" "}
                              </MenuItem>
                              <MenuItem value="Moderna"> Moderna </MenuItem>
                              <MenuItem value="Janssen">Janssen</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                  {/* {process.env.REACT_APP_LABEL_VACCINE == "yes" ? (
                    <Grid item xs={12} md={12} sm={12}>
                      <FormLabel component="legend" className="paddingAdj">
                        Please note that the Community Vaccination Center at St.
                        Thomas is currently not offering Moderna vaccines.
                      </FormLabel>
                    </Grid>
                  ) : (
                    ""
                  )} */}
                  <Grid item xs={12} md={12} sm={12}>
                    {ChildCheck == "Yes" ? (
                      <FormLabel component="legend" className="paddingAdj">
                        Does the child have any disabilities or need any
                        accommodations on site?
                      </FormLabel>
                    ) : (
                      <FormLabel component="legend" className="paddingAdj">
                        Does the individual have any disabilities or need any
                        accommodations on site?
                      </FormLabel>
                    )}
                    <RadioGroup
                      aria-label="disability"
                      name="disability"
                      value={disability}
                      onChange={handleChangedisability}
                    >
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="Hard of hearing, or deaf"
                          control={<Radio />}
                          label="Hard of hearing, or deaf"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="Visually impaired, or blind"
                          control={<Radio />}
                          label="Visually impaired, or blind"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="Requires wheelchair assistance"
                          control={<Radio />}
                          label="Requires wheelchair assistance"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="No special needs "
                          control={<Radio />}
                          label="No special needs "
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="Others"
                          control={<Radio />}
                          label="Other special need"
                        />
                      </Grid>
                    </RadioGroup>
                  </Grid>
                  {disability === "Others" ? (
                    <Grid item xs={12} md={6} sm={12}>
                      <TextField
                        id="standard-basic10"
                        label="Other special need"
                        style={{ width: "100%" }}
                        InputProps={{
                          className: classes.input,
                        }}
                        className={classes.textField}
                        required
                        onChange={(e) =>{
                          let value = e.target.value.replaceAll('"',"'")
                          setdescribeDisablity(value.replaceAll("''","'"))
                        }}
                        name="describeDisablity"
                        value={describeDisablity}
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={8} sm={12}>
                    <TextField
                      id="standard-basic3"
                      label="Please share any additional comments or preferences here."
                      InputProps={{
                        className: classes.input,
                      }}
                      autoComplete="off"
                      className={classes.textField}
                      onChange={(e) =>{
                        let value = e.target.value.replaceAll('"',"'")
                        setcomments(value.replaceAll("''","'"))
                      }}
                      name="comments"
                      value={comments}
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={12} sm={12}>
                    <FormLabel component="legend" className="paddingAdj">
                      The CVC location will be changing starting May 10th, 2021.
                      When are you looking to book an appointment? *
                    </FormLabel>
                    <RadioGroup
                      aria-label="vaccinedose"
                      name="selectcvcmonth"
                      value={selectcvcmonth}
                      onChange={CheckhandleCVC}
                    >
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="april"
                          control={<Radio />}
                          label="April - May 7th"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="may"
                          control={<Radio />}
                          label="May 10th and onwards"
                        />
                      </Grid>
                    </RadioGroup>
                  </Grid> */}
                  <Grid item xs={12} md={12} sm={12}>
                    <div
                      className={active1 === true ? "buttonNext" : "buttonNext"}
                    >
                      <button onClick={moveNext}>Next</button>
                    </div>
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <div className="accordionbg">
              <div className="accSummary">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <Typography ref={myRef1}>
                    Additional Demographics
                    <FormHelperText style={{ color: "red" }}>
                      <span>
                        {" "}
                        {errorEducationCheck ? errorEducationCheck : ""}
                      </span>
                      <span> {errorOccupation ? errorOccupation : ""}</span>
                      <span> {errorRace ? errorRace : ""}</span>
                      <span> {errorGender ? errorGender : ""}</span>
                      <span> {errorMember ? errorMember : ""}</span>
                      <span> {errorEthnicity ? errorEthnicity : ""}</span>
                      <span> {errorAddress ? errorAddress : ""}</span>
                      <span> {errorCity ? errorCity : ""}</span>
                      <span> {errorCountry ? errorCountry : ""}</span>
                      <span> {errorCounty ? errorCounty : ""}</span>
                      <span> {errorZip ? errorZip : ""}</span>
                      <span> {errorState ? errorState : ""}</span>
                    </FormHelperText>
                  </Typography>
                </AccordionSummary>
              </div>
            </div>
            <AccordionDetails>
              <Typography style={{ width: "100%" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} sm={12}>
                    <FormControl
                      className={classes.formControl}
                      style={{ width: "100%" }}
                    >
                      <InputLabel>Race</InputLabel>
                      <Select
                        className="form-dropdown"
                        value={racedSelect}
                        onChange={handleChangeRace}
                        name="race"
                      >
                        {raced.map((index) => (
                          <MenuItem key={index.raceName} value={index.raceName}>
                            {index.raceName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={12}>
                    <FormControl
                      className={classes.formControl}
                      style={{ width: "100%" }}
                    >
                      <InputLabel>Gender</InputLabel>
                      <Select
                        className="form-dropdown"
                        value={gender}
                        onChange={handleChangeGender}
                        required
                      >
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                        <MenuItem value={"Ambiguous"}>Non-binary</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={12} className="radioAwesome">
                    <FormLabel component="legend">Ethnicity</FormLabel>
                    <RadioGroup
                      aria-label="ethnicity"
                      name="ethnicity"
                      value={ethnicity}
                      onChange={handleChangeEthnicity}
                    >
                      <FormControlLabel
                        value="Hispanic"
                        control={<Radio />}
                        label="Hispanic"
                      />
                      <FormControlLabel
                        value="Non-Hispanic"
                        control={<Radio />}
                        label="Non-Hispanic"
                      />
                      <FormControlLabel
                        value="Unknown"
                        control={<Radio />}
                        label="Unknown"
                      />
                    </RadioGroup>
                  </Grid>
                  {ChildCheck === "Yes" ? (
                    <Grid item xs={12} md={6} sm={12}>
                      <FormLabel component="legend">
                        Is the child currently attending in-person school or
                        daycare? *
                      </FormLabel>
                      <RadioGroup
                        aria-label="EduCheck"
                        name="EduCheck"
                        value={EduCheck}
                        onChange={(e) => setEduCheck(e.target.value)}
                      >
                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            value="In-person school/daycare"
                            control={<Radio />}
                            label="Yes"
                          />
                        </Grid>
                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            value="Not part of any listed groups"
                            control={<Radio />}
                            label="No"
                          />
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={12} sm={12}>
                      <FormLabel component="legend" className="paddingAdj">
                        Is the patient a member of one of these groups?
                      </FormLabel>
                      <RadioGroup
                        aria-label="member"
                        name="member"
                        value={member}
                        onChange={(e) => setMember(e.target.value)}
                      >
                        <Grid item xs={12} md={6} sm={12}>
                          <FormControlLabel
                            value="First response/Healthcare"
                            control={<Radio />}
                            label="First responder/Healthcare worker"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                          <FormControlLabel
                            value="Congregate facility"
                            control={<Radio />}
                            label="Congregate facility worker/resident"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                          <FormControlLabel
                            value="Hospitality/Transportation"
                            control={<Radio />}
                            label="Hospitality/Transportation worker"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                          <FormControlLabel
                            value="In-person school/daycare"
                            control={<Radio />}
                            label="In-person school/daycare worker"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                          <FormControlLabel
                            value="Not reported"
                            control={<Radio />}
                            label="Not part of any listed groups"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                          <FormControlLabel
                            value="Others"
                            control={<Radio />}
                            label="Other Group (Please indicate below)"
                          />
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  )}
                  {member === "Others" ? (
                    <Grid item xs={12} md={6} sm={12}>
                      <TextField
                        id="standard-basic10"
                        label="Occupation/Affliation"
                        style={{ width: "100%" }}
                        InputProps={{
                          className: classes.input,
                        }}
                        className={classes.textField}
                        required
                        onChange={(e) =>{
                          let value = e.target.value.replaceAll('"',"'")
                          setoccupation(value.replaceAll("''","'"))
                        }}
                        name="occupation"
                        autoComplete="new-password"
                        value={occupation}
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={6} sm={12}>
                    <TextField
                      id="standard-basic5"
                      label="Address 1"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      // required
                      onChange={(e) =>{
                        let value = e.target.value.replaceAll('"',"'")
                        setaddressOne(value.replaceAll("''","'"))
                      }}
                      name="addressOne"
                      autoComplete="new-password"
                      value={addressOne}
                      inputProps={{ maxLength: 200 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={12}>
                    <TextField
                      id="standard-basic6"
                      label="Address 2"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      onChange={(e) =>{
                        let value = e.target.value.replaceAll('"',"'")
                        setaddressTwo(value.replaceAll("''","'"))
                      }}
                      name="addressTwo"
                      autoComplete="new-password"
                      value={addressTwo}
                      inputProps={{ maxLength: 200 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={12}>
                    <FormControl
                      className="form-dropdown"
                      fullWidth
                      variant="standard"
                    >
                      <InputLabel>Country *</InputLabel>
                      <Select
                        className="form-dropdown"
                        name="country"
                        value={country}
                        onChange={handleChangeCountry}
                        required
                      >
                        {countryList.map((item) => (
                          <MenuItem
                            key={item.countryCode}
                            value={item.countryCode}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={12}>
                    <FormControl
                      className={classes.formControl}
                      style={{ width: "100%" }}
                    >
                      <InputLabel>State/Territory *</InputLabel>
                      <Select
                        className="form-dropdown"
                        name="state"
                        value={state}
                        onChange={handleChangeState}
                        required
                      >
                        {stateSelect.map((item) => (
                          <MenuItem
                            key={item[1].stateCode}
                            value={item[1].stateCode + "," + item[0]}
                          >
                            {item[0]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={12}>
                    <TextField
                      id="standard-basic8"
                      label="Zipcode/Postal Code *"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      onChange={(e) => setzipCode(e.target.value)}
                      name="zipCode"
                      type="number"
                      autoComplete="new-password"
                      value={zipCode}
                      inputProps={{ maxLength: 20 }}
                    />
                  </Grid>
                  {countyTemp.length ? (
                    <Grid item xs={12} md={6} sm={12}>
                      <FormControl
                        className={classes.formControl}
                        style={{ width: "100%" }}
                      >
                        <InputLabel>County/District *</InputLabel>
                        <Select
                          className="form-dropdown"
                          onChange={countyChange}
                          value={county}
                        >
                          {countyTemp !== null && countyTemp !== undefined
                            ? countyTemp.map((item) => (
                                <MenuItem key={item[1].id} value={item[0]}>
                                  {item[0]}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={6} sm={12}>
                      <TextField
                        id="standard-basic8"
                        InputProps={{
                          className: classes.input,
                        }}
                        className={classes.textField}
                        label="City/Estate"
                        variant="standard"
                        name="city"
                        inputProps={{ maxLength: 40, autoComplete: "none" }}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <div
                    className={active2 === true ? "buttonNext" : "buttonNext"}
                  >
                    <button onClick={moveNext1}>Next</button>
                  </div>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
          {ChildCheck === "Yes" ? (
            <>
              <Accordion
                square
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <div className="accordionbg">
                  <div className="accSummary">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3d-content"
                      id="panel3d-header"
                    >
                      <Typography ref={myRef2}>
                        Recent Health Updates
                        <FormHelperText style={{ color: "red" }}>
                          <span> {symptomsError ? symptomsError : ""}</span>
                          <span>
                            {" "}
                            {symptomsListError ? symptomsListError : ""}
                          </span>
                          <span>
                            {" "}
                            {symptomsDateError ? symptomsDateError : ""}
                          </span>
                          <span>
                            {" "}
                            {ReceivedVaccineError ? ReceivedVaccineError : ""}
                          </span>
                          <span>
                            {" "}
                            {allergicToAnythingError
                              ? allergicToAnythingError
                              : ""}
                          </span>
                          <span>
                            {" "}
                            {seriousReactionError ? seriousReactionError : ""}
                          </span>
                          <span>
                            {" "}
                            {weakenedImmuneSystemError
                              ? weakenedImmuneSystemError
                              : ""}
                          </span>
                          <span>
                            {" "}
                            {conditionsCheckError ? conditionsCheckError : ""}
                          </span>
                          <span>
                            {" "}
                            {treatmentCheckError ? treatmentCheckError : ""}
                          </span>
                          <span>
                            {" "}
                            {diagnosedCovidError ? diagnosedCovidError : ""}
                          </span>
                        </FormHelperText>
                      </Typography>
                    </AccordionSummary>
                  </div>
                </div>
                <AccordionDetails>
                  <Typography style={{ width: "100%" }}>
                    <Grid spacing={2} container>
                      <Grid item xs={12} md={12} sm={12}>
                        <Grid item xs={12} md={12} sm={12}>
                          <FormLabel component="legend" className="paddingAdj">
                            Has the child been experiencing any Covid-19
                            symptoms? *
                          </FormLabel>
                          <RadioGroup
                            aria-label="symtomatic"
                            name="symtomatic"
                            value={symptomatic}
                            onChange={handleSymptomatic}
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </Grid>
                        {symptomatic === "Y" ? (
                          <FormGroup>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={stateChecked.Shortness_of_breath}
                                    onChange={handleChangeCheck}
                                    name="Shortness_of_breath"
                                    value="Shortness of breath"
                                  />
                                }
                                label="Shortness of breath"
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={stateChecked.Cough}
                                    onChange={handleChangeCheck}
                                    name="Cough"
                                    value="Cough"
                                  />
                                }
                                label="Cough"
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={stateChecked.Chills}
                                    onChange={handleChangeCheck}
                                    name="Chills"
                                    value="Chills"
                                  />
                                }
                                label="Chills"
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={stateChecked.Fever}
                                    onChange={handleChangeCheck}
                                    name="Fever"
                                    value="Fever >100.4"
                                  />
                                }
                                label="Fever >100.4"
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={stateChecked.Difficulty_breathing}
                                    onChange={handleChangeCheck}
                                    name="Difficulty_breathing"
                                    value="Difficulty breathing"
                                  />
                                }
                                label="Difficulty breathing"
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={stateChecked.Headache}
                                    onChange={handleChangeCheck}
                                    name="Headache"
                                    value="Headache"
                                  />
                                }
                                label="Headache"
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={stateChecked.Fatigue}
                                    onChange={handleChangeCheck}
                                    name="Fatigue"
                                    value="Fatigue"
                                  />
                                }
                                label="Fatigue"
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={stateChecked.Diarrhea}
                                    onChange={handleChangeCheck}
                                    name="Diarrhea"
                                    value="Diarrhea"
                                  />
                                }
                                label="Diarrhea"
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={stateChecked.Vomiting}
                                    onChange={handleChangeCheck}
                                    name="Vomiting"
                                    value="Vomiting"
                                  />
                                }
                                label="Vomiting"
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={stateChecked.Sore_Throat}
                                    onChange={handleChangeCheck}
                                    name="Sore_Throat"
                                    value="Sore Throat"
                                  />
                                }
                                label="Sore Throat"
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={stateChecked.Nausea}
                                    onChange={handleChangeCheck}
                                    name="Nausea"
                                    value="Nausea"
                                  />
                                }
                                label="Nausea"
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={stateChecked.Loss_of_taste}
                                    onChange={handleChangeCheck}
                                    name="Loss_of_taste"
                                    value="Loss of taste"
                                  />
                                }
                                label={
                                  <Typography
                                    variant="inherit"
                                    component="span"
                                    style={{ paddingTop: "1em" }}
                                  >
                                    Loss of taste <br /> and / or smell{" "}
                                  </Typography>
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      stateChecked.Body_and_or_muscle_aches
                                    }
                                    onChange={handleChangeCheck}
                                    name="Body_and_or_muscle_aches"
                                    value="Body and/or muscle aches"
                                  />
                                }
                                label="Body and/or muscle aches"
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      stateChecked.Congestion_and_or_runny_nose
                                    }
                                    onChange={handleChangeCheck}
                                    name="Congestion_and_or_runny_nose"
                                    value="Congestion and/or runny nose"
                                  />
                                }
                                label="Congestion and/or runny nose"
                              />
                            </Grid>
                          </FormGroup>
                        ) : null}
                      </Grid>
                      {symptomatic === "Y" &&
                      Object.keys(stateChecked).filter(
                        (e) => stateChecked[e] === true
                      ).length > 0 &&
                      stateChecked !== undefined ? (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sm={12}
                            style={{ marginTop: "1em" }}
                          >
                            <KeyboardDatePicker
                              className="form-date"
                              fullWidth
                              clearable
                              value={selectedDateSymptom}
                              onChange={(date) => handleDateChangeSymptom(date)}
                              format="MM/dd/yyyy"
                              label="Symptom start date"
                              maxDate={new Date()}
                              autoOk
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      ) : null}
                      <Grid item xs={12} md={12} sm={12}>
                        <FormLabel component="legend" className="paddingAdj">
                          Has the child received any vaccinations in the past
                          four weeks? *
                        </FormLabel>
                        <RadioGroup
                          aria-label="symtomatic"
                          name="Covid19vaccine"
                          value={Covid19vaccine}
                          onChange={(e) => setCovid19vaccine(e.target.value)}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes, he/she has received a vaccine"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No, he/she has not yet received a vaccine"
                          />
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={12} md={12} sm={12}>
                        <h4 className="secCompany">
                          Primary Insurance Company Information
                        </h4>
                      </Grid>
                      <Grid item xs={12} md={12} sm={12}>
                        <TextField
                          id="standard-basic11"
                          label="Name of Insurance Company"
                          style={{ width: "100%" }}
                          InputProps={{
                            className: classes.input,
                          }}
                          className={classes.textField}
                          name="primaryInsuranceCompanyName"
                          autoComplete="off"
                          onChange={(e) =>
                            setprimaryInsuranceCompanyName(e.target.value)
                          }
                          value={primaryInsuranceCompanyName}
                          inputProps={{ maxLength: 200 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <TextField
                          id="standard-basic13"
                          label="Primary Insurance Carrier ID#"
                          style={{ width: "100%" }}
                          InputProps={{
                            className: classes.input,
                          }}
                          className={classes.textField}
                          name="primaryCarrierId"
                          autoComplete="off"
                          onChange={(e) => setprimaryCarrierId(e.target.value)}
                          value={primaryCarrierId}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <TextField
                          id="standard-basic14"
                          label="Group ID#"
                          style={{ width: "100%" }}
                          InputProps={{
                            className: classes.input,
                          }}
                          className={classes.textField}
                          name="primaryGroupId"
                          autoComplete="off"
                          onChange={(e) => setprimaryGroupId(e.target.value)}
                          value={primaryGroupId}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} sm={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={newstateChecked}
                              onChange={handleChangeCheckInsurance}
                              name="Do you want to add your secondary insurance company information"
                            />
                          }
                          label="Do you want to add your secondary insurance company information?"
                        />
                      </Grid>
                      {newstateChecked === true ? (
                        <>
                          <Grid item xs={12} md={12} sm={12}>
                            <h4 className="secCompany">
                              Secondary Insurance Company Information
                            </h4>
                            <TextField
                              id="standard-basic15"
                              label="Name of Insurance Company"
                              style={{ width: "100%" }}
                              InputProps={{
                                className: classes.input,
                              }}
                              className={classes.textField}
                              name="secondaryInsuranceCompanyName"
                              autoComplete="off"
                              onChange={(e) =>
                                setsecondaryInsuranceCompanyName(e.target.value)
                              }
                              value={secondaryInsuranceCompanyName}
                              inputProps={{ maxLength: 200 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12}>
                            <TextField
                              id="standard-basic17"
                              label="Primary Insurance Carrier ID#"
                              style={{ width: "100%" }}
                              InputProps={{
                                className: classes.input,
                              }}
                              className={classes.textField}
                              name="secondaryCarrierId"
                              autoComplete="off"
                              onChange={(e) =>
                                setsecondaryCarrierId(e.target.value)
                              }
                              value={secondaryCarrierId}
                              inputProps={{ maxLength: 50 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12}>
                            <TextField
                              id="standard-basic18"
                              label="Group ID#"
                              style={{ width: "100%" }}
                              InputProps={{
                                className: classes.input,
                              }}
                              className={classes.textField}
                              name="secondaryGroupId"
                              autoComplete="new-password"
                              onChange={(e) =>
                                setsecondaryGroupId(e.target.value)
                              }
                              value={secondaryGroupId}
                              inputProps={{ maxLength: 50 }}
                            />
                          </Grid>
                        </>
                      ) : null}
                      <Grid item xs={12} md={12} sm={12}>
                        <FormLabel component="legend" className="paddingAdj">
                          Is the child allergic to anything including any food,
                          any vaccine, any known vaccine component or latex? *
                        </FormLabel>
                        <RadioGroup
                          aria-label="allergicToAnything"
                          name="allergicToAnything"
                          value={allergicToAnything}
                          onChange={(e) =>
                            setallergicToAnything(e.target.value)
                          }
                        >
                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                          </Grid>
                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </Grid>
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={12} md={12} sm={12}>
                        <FormLabel component="legend" className="paddingAdj">
                          Has the child ever had a serious reaction after
                          receiving a vaccination? *
                        </FormLabel>
                        <RadioGroup
                          aria-label="seriousReaction"
                          name="seriousReaction"
                          value={seriousReaction}
                          onChange={(e) => setseriousReaction(e.target.value)}
                        >
                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                          </Grid>
                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </Grid>
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={12} md={12} sm={12}>
                        <FormLabel component="legend" className="paddingAdj">
                          Does the child or anyone they live with have a
                          weakened immune system? *
                        </FormLabel>
                        <RadioGroup
                          aria-label="weakenedImmuneSystem"
                          name="weakenedImmuneSystem"
                          value={weakenedImmuneSystem}
                          onChange={(e) =>
                            setweakenedImmuneSystem(e.target.value)
                          }
                        >
                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                          </Grid>
                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </Grid>
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={12} md={12} sm={12}>
                        <FormLabel component="legend" className="paddingAdj">
                          Does the child have any history of seizures or
                          neurological conditions? *
                        </FormLabel>
                        <RadioGroup
                          aria-label="conditionsCheck"
                          name="conditionsCheck"
                          value={conditionsCheck}
                          onChange={(e) => setconditionsCheck(e.target.value)}
                        >
                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                          </Grid>
                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </Grid>
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={12} md={12} sm={12}>
                        <FormLabel component="legend" className="paddingAdj">
                          Does the child or anyone they live with take steroids,
                          anti-cancer drugs, or receive x-ray treatments? *
                        </FormLabel>
                        <RadioGroup
                          aria-label="treatmentCheck"
                          name="treatmentCheck"
                          value={treatmentCheck}
                          onChange={(e) => settreatmentCheck(e.target.value)}
                        >
                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                          </Grid>
                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </Grid>
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={12} md={12} sm={12}>
                        <FormLabel component="legend" className="paddingAdj">
                          Has the child been diagnosed with COVID within the
                          past 3 months?
                        </FormLabel>
                        <RadioGroup
                          aria-label="diagnosedCovid"
                          name="diagnosedCovid"
                          value={diagnosedCovid}
                          onChange={(e) => setdiagnosedCovid(e.target.value)}
                        >
                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                          </Grid>
                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </Grid>
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                      <div
                        className={
                          active3 === true ? "buttonNext" : "buttonNext"
                        }
                      >
                        <button onClick={moveNext2}>Next</button>
                      </div>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <div className="accordionbg">
                  <div className="accSummary">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3d-content"
                      id="panel3d-header"
                    >
                      <Typography ref={myRef3}>
                        Authorizing Consent
                        <FormHelperText style={{ color: "red" }}>
                          <span> {consentError ? consentError : ""}</span>
                          <span>
                            {" "}
                            {parentGuardianSignatureError
                              ? parentGuardianSignatureError
                              : ""}
                          </span>
                          <span>
                            {" "}
                            {consentDateError ? consentDateError : ""}
                          </span>
                        </FormHelperText>
                      </Typography>
                    </AccordionSummary>
                  </div>
                </div>
                <AccordionDetails>
                  <Typography style={{ width: "100%" }}>
                    <Grid spacing={2} container>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        sm={12}
                        style={{ textAlign: "center" }}
                      >
                        <a
                          href={VaccineScheduling}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Vaccine Information Statement: Pfizer
                        </a>
                      </Grid>
                      {/* <Grid item xs={12} md={4} sm={12} style={{ textAlign: "center" }}>
                        <a href={VaccineScheduling} target='_blank' rel='noopener noreferrer'>
                          Moderna
                        </a>
                      </Grid>
                      <Grid item xs={12} md={4} sm={12} style={{ textAlign: "center" }}>
                        <a href={VaccineScheduling} target='_blank' rel='noopener noreferrer'>
                          Janssen
                        </a>
                      </Grid> */}
                      <Grid item xs={12} md={12} sm={12}>
                        <p style={{ lineHeight: "1.7em" }}>
                          I have been given a copy and have read, or have had
                          explained to me, the information in the Vaccine
                          Information Statements for the vaccines indicated. I
                          have had the chance to ask questions that were
                          answered to my satisfaction. I believe that I
                          understand the benefits and risks of the vaccines
                          requested and ask that the vaccines indicated be given
                          to me or the person named for whom I am authorized to
                          make this request. I acknowledge I have been advised
                          to wait in the vaccination area for 15-20 minutes
                          after being vaccinated for observation. I will call
                          911 or go to the nearest hospital if I experience a
                          severe reaction{" "}
                          <TextField
                            variant="standard"
                            autoComplete="off"
                            onChange={(e) => setconsent(e.target.value)}
                            value={consent}
                          />
                          (Initial)
                        </p>
                      </Grid>
                      <Grid item xs={12} md={12} sm={12}>
                        <p>
                          {" "}
                          For insurance and Medicare Beneficiaries with Part B:
                          I authorize the release of any medical or other
                          information necessary to process this claim. I
                          authorize payment of medical benefits to the
                          undersigned physician or supplier for services
                          described.
                        </p>
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <TextField
                          id="standard-basic11"
                          label="Parent/Guardian Signature *"
                          style={{ width: "100%" }}
                          InputProps={{
                            className: classes.input,
                          }}
                          className={classes.textField}
                          name="Parent/Guardian Signature"
                          autoComplete="off"
                          onChange={(e) =>
                            setparentGuardianSignature(e.target.value)
                          }
                          value={parentGuardianSignature}
                          inputProps={{ maxLength: 200 }}
                        />
                      </Grid>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item xs={12} md={6} sm={12}>
                          <KeyboardDatePicker
                            className="form-date"
                            fullWidth
                            clearable
                            value={consentDate}
                            onChange={(date) => setconsentDate(date)}
                            format="MM/dd/yyyy"
                            label="Date *"
                            maxDate={new Date()}
                            autoOk
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                      <div
                        className={
                          active4 === true ? "buttonNext" : "buttonNext"
                        }
                      >
                        <button onClick={moveNext3}>Next</button>
                      </div>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </>
          ) : null}
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div>
              <button className="resetform" onClick={resetForm}>
                <span>Reset</span>
              </button>
            </div>
            <div className="submit">
              <button className="btn" onClick={formSubmit}>
                <span>Continue to schedule</span>
                <span>
                  {" "}
                  <i className="fa fa-arrow-right"></i>{" "}
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}
